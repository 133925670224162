<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-mentor-surveys">
      Mentor Surveys
    </div>

    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div v-else>
      <div class="card my-2" v-if="successMessage || errorMessage">
        <div class="box-style">
          <error-banner
            class="mb-2"
            v-if="successMessage"
            title="Success"
            :messages="[successMessage]"
            success
          />
          <error-banner
            class="mb-2"
            v-if="errorMessage"
            title="Error"
            :messages="[errorMessage]"
            error
          />
        </div>
      </div>
      <div class="card my-2">
        <div class="box-style">
          <div class="inline-block">
            <label class="thirdhead-text pr-2" for="dropdown-term-id"
              >Term</label
            >
            <select
              class="rounded border border-steel-gray"
              id="dropdown-term-id"
              v-model="term"
              @change="changeDropDown"
            >
              <option
                v-for="term in this.terms"
                :key="term.term_id"
                :value="term"
              >
                {{ term.name }}
              </option>
            </select>
          </div>

          <div v-if="!term" class="inline-block ml-2">
            <label class="thirdhead-text"
              >Please select a term before attempting to send a survey.</label
            >
          </div>
          <div
            v-else-if="!verifySubmission && !verifyResend"
            class="inline-block"
          >
            <Button
              class="ml-2"
              text="Start Survey"
              @click="verifySubmission = true"
            />
            <Button
              class="ml-2"
              text="Resend Survey"
              @click="verifyResend = true"
            />
          </div>
          <div v-else-if="verifySubmission" class="inline-block ml-2">
            <label class="thirdhead-text"
              >Are you sure you want to send this survey for term
              {{ term.name }}?</label
            >
            <Button class="mx-2" text="Yes" @click="sendSurvey" />
            <Button
              text="No"
              :primary="false"
              @click="verifySubmission = false"
            />
          </div>
          <div v-else-if="verifyResend" class="inline-block ml-2">
            <label class="thirdhead-text"
              >Are you sure you want to resend this survey to all Mentors marked
              "Follow Up"?</label
            >
            <Button class="mx-2" text="Yes" @click="resendSurvey" />
            <Button text="No" :primary="false" @click="verifyResend = false" />
          </div>
        </div>
      </div>

      <modal
        v-if="displayModal"
        label="Survey Results"
        show_cancel
        cancel_btn_text="Close"
        no_submit
        blocking
        large
        @close="displayModal = false"
      >
        <template v-slot:content>
          <div class="card">
            <div v-if="!isPopupLoading">
              <template v-if="surveyAnswers.fields && surveyAnswers.responses">
                <div
                  class="pt-2"
                  v-for="(field, key) in surveyAnswers.fields"
                  :key="key"
                >
                  <div>
                    {{ field.name }}
                  </div>
                  <div class="thirdhead-text">
                    {{ findAnswer(field) }}
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              <LoadingSpinner />
            </div>
          </div>
        </template>
      </modal>

      <div class="card">
        <div class="box-style">
          <div v-if="!tableLoading">
            <dynamic-table
              :records="this.mentors"
              :fields="tableConfiguration"
              :pagination-page="paginationPage"
              :pagination-total-items="paginationTotalItems"
              @pageChanged="nextPage($event)"
              no-data-msg="No mentors to display"
              hover
              striped
              pagination
            >
              <template #cell(Name)="{ item }">
                {{ item.mentor.firstname }} {{ item.mentor.lastname }}
              </template>
              <template #cell(Salutation)="{ item }">
                <template
                  v-if="
                    item.mentor?.employer[0]?.job_title &&
                    item.mentor?.employer[0]?.job_title.trim().includes('Judge')
                  "
                >
                  Dear Judge {{ item.mentor.lastname }},
                </template>
                <template
                  v-else-if="
                    item.mentor?.employer[0]?.job_title &&
                    item.mentor?.employer[0]?.job_title
                      .trim()
                      .includes('Justice')
                  "
                >
                  Dear Justice {{ item.mentor.lastname }},
                </template>
                <template
                  v-else-if="
                    item.mentor?.employer[0]?.job_title &&
                    item.mentor?.employer[0]?.job_title
                      .trim()
                      .includes('Referee')
                  "
                >
                  Dear Referee {{ item.mentor.lastname }},
                </template>
                <template v-else>
                  Dear {{ item.mentor.prefix }} {{ item.mentor.lastname }},
                </template>
              </template>
              <template #cell(Students)="{ item }">
                <template v-if="item.link_info.students === undefined">
                  <div
                    v-for="(student, key) in item.mentor.student_pairings"
                    :key="key"
                    class="my-1"
                  >
                    {{ student.student_at_level.student.firstname }}
                    {{ student.student_at_level.student.lastname }}
                  </div>
                </template>
                <template v-else>
                  <div
                    :class="key !== findNames(item).length - 1 ? 'mb-2' : ''"
                    v-for="(student, key) in findNames(item)"
                    :key="key"
                  >
                    {{ student.name }}
                  </div>
                </template>
              </template>
              <template #cell(Status)="{ item }">
                <template v-if="item.link_info.status_id === undefined"
                  >Not Sent</template
                >
                <template v-else>{{ item.link_info.status_id }}</template>
              </template>
              <template #cell(Answer)="{ item }">
                <a
                  v-if="
                    item.link_info.status_id !== 'completed' &&
                    item.link_info.status_id !== undefined
                  "
                  :href="routeToAnswer(item.link_info.link_id)"
                >
                  <Button
                    class="py-0 px-0 my-1"
                    :primary="false"
                    text="Answer"
                  />
                </a>
                <template v-else-if="item.link_info.status_id !== undefined">
                  <div
                    v-for="student in item.link_info.students"
                    :key="student.student_id"
                  >
                    <Button
                      class="py-0 px-0 my-1"
                      text="View"
                      :disabled="student.survey_response_id === undefined"
                      @click="viewSurveyResults(student)"
                    />
                  </div>
                </template>
              </template>
            </dynamic-table>
          </div>
          <LoadingSpinner v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import { get, put, post } from "@/composables/httpUtil";
import ErrorBanner from "@/components/forms/ErrorBanner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import Modal from "@/components/modal/Modal";
import {mapGetters} from "vuex";

export default {
  name: "MentorAdminSurveys",
  data() {
    return {
      isLoading: false,
      isPopupLoading: false,
      tableLoading: false,
      displayModal: false,
      surveyAnswers: null,
      successMessage: "",
      verifySubmission: false,
      verifyResend: false,
      terms: [],
      term: null,
      errorMessage: "",
      paginationPage: 1,
      itemsPerPage: 25,
      paginationTotalItems: 0,
      tableConfiguration: [
        {
          display: "Name",
          name: "Name",
          sortable: false,
          class: "",
        },
        {
          display: "Salutation",
          name: "Salutation",
          sortable: false,
          class: "",
        },
        {
          display: "Students",
          name: "Students",
          sortable: false,
          class: "",
        },
        // {
        //   display: "Comments",
        //   name: "todo",
        //   sortable: false,
        //   class: "",
        // },
        // {
        //   display: "Repair",
        //   name: "todo",
        //   sortable: false,
        //   class: "",
        // },
        {
          display: "Mentor Status",
          name: "mentor.mentor_status",
          sortable: false,
          class: "",
        },
        {
          display: "Survey Status",
          name: "Status",
          sortable: false,
          class: "",
        },
        {
          display: "Answer",
          name: "Answer",
          sortable: false,
          class: "text-center",
        },
      ],
      mentors: [],
    };
  },
  created() {
    this.isLoading = true;
    this.get(`admin/terms`).then((response) => {
      this.terms = response.data;
      this.term = this.lookup_previous_term;
      //doing this so the drop down is set correctly :)
      if (
        this.$route.query.term !== undefined &&
        this.$route.query.term !== ""
      ) {
        this.term = this.terms.find(
          (term) => term.term_id === this.$route.query.term
        );
      }
      this.fetchMentors();
    });
  },
  methods: {
    get,
    put,
    post,
    sendSurvey() {
      this.clearMessages();

      this.isLoading = true;
      const body = {
        term_id: this.term.term_id,
        start_date: "",
        end_date: "",
      };
      this.post(`admin/mentors/survey`, body).then(
        (response) => {
          this.successMessage = response.message;
          this.verifySubmission = false;
          this.isLoading = false;
        },
        (err) => {
          this.errorMessage = err.message
            ? err.message
            : "There was an error in starting the survey.";
          this.verifySubmission = false;
          this.isLoading = false;
        }
      );
    },
    resendSurvey() {
      this.clearMessages();
      let url;
      if (this.term?.term_id) {
        url = `admin/mentors/survey/resend?termId=${this.term.term_id}`;
      } else {
        // url = `admin/mentors/survey?page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      }
      this.isLoading = true;
      this.post(url).then(
        (response) => {
          this.successMessage = response.message;
          this.verifyResend = false;
          this.isLoading = false;
        },
        (err) => {
          this.errorMessage = err.message
            ? err.message
            : "There was an error in starting the survey.";
          this.verifyResend = false;
          this.isLoading = false;
        }
      );
    },
    nextPage(pageChanged) {
      this.paginationPage = pageChanged.newPage;
      this.fetchMentors();
    },
    changeDropDown() {
      this.$router.push({
        query: { term: this.term.term_id },
      });
      this.verifySubmission = false;
      this.verifyResend = false;
      this.fetchMentors();
    },
    fetchMentors() {
      this.tableLoading = true;
      this.clearMessages();
      let url;
      if (this.term?.term_id) {
        url = `admin/mentors/survey?termId=${this.term.term_id}&page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      } else {
        // url = `admin/mentors/survey?page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      }
      this.get(url).then((response) => {
        this.mentors = response.data;
        this.paginationPage = response.page_number;
        this.paginationTotalItems = response.count;
        this.isLoading = false;
        this.tableLoading = false;
      });
    },
    openWindow(linkId) {
      window.open(
        process?.env?.VUE_APP_BASE_API_URL + "surveys/info/" + linkId
      );
    },
    routeToAnswer(linkId) {
      return process?.env?.VUE_APP_BASE_API_URL + "surveys/info/" + linkId;
    },
    viewSurveyResults(student) {
      this.displayModal = true;
      this.isPopupLoading = true;
      this.get(`admin/surveys/response/${student.survey_response_id}`).then(
        (results) => {
          this.surveyAnswers = results;
          this.isPopupLoading = false;
        },
        () => {
          this.isPopupLoading = false;
          this.displayModal = false;
        }
      );
    },
    clearMessages() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    findNames(mentor) {
      let studentsArray = [];
      if (mentor.link_info.students !== undefined) {
        mentor.link_info.students.forEach((studentId) => {
          const studentRecord = mentor.mentor.student_pairings.find(
            (student) =>
              studentId.student_id ===
              student.student_at_level.student.person_id
          );
          if (studentRecord != undefined) {
            const surveyItem = {
              name:
                studentRecord.student_at_level.student.firstname +
                " " +
                studentRecord.student_at_level.student.lastname,
              studentId: studentRecord.student_at_level.student.person_id,
            };
            studentsArray.push(surveyItem);
          }
        });
      }
      return studentsArray;
    },
    findAnswer(field) {
      let answer = "No response found";
      const response = this.surveyAnswers.responses.find(
        (response) => response.field_id === field.field_id
      );

      if (response !== undefined) {
        if (response.value && response !== "") {
          answer = response.value;
        } else {
          answer = "No response given";
        }
      }

      return answer;
    },
  },
  components: {
    Button,
    ErrorBanner,
    DynamicTable,
    LoadingSpinner,
    Modal,
  },
  computed: {
    ...mapGetters(["lookup_previous_term"]),
  },
};
</script>

<style scoped></style>
